export default {
  director: (state) => state.director || [],
  seniorProfessionals: (state) => state.seniorProfessionals || [],
  professionals: (state) => state.professionals || [],
  practitioners: (state) => state.practitioners || [],
  seniorSupervisors: (state) => state.seniorSupervisors || [],
  supervisors: (state) => state.supervisors || [],
  statisticians: (state) => state.statisticians || [],
  topPerformance: (state) => state.topPerformance || [],
};
