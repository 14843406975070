import Axios from 'axios';

export default {
  async getAgents({ commit }) {
    try {
      const { data } = await Axios.get('/agents');
      commit('setAgents', data);
    } catch (e) {
      console.error(e.message);
    }
  },
  async getAgentStatus({ commit }) {
    try {
      const { data } = await Axios.get('/agent-status');
      commit('updateStatus', data);
    } catch (e) {
      console.error(e.message);
    }
  },
  async getAgentStation({ commit }) {
    try {
      const { data } = await Axios.get('/agent-stations');
      commit('updateStation', data);
    } catch (e) {
      console.error(e.message);
    }
  },
};
