import Vue from 'vue';

export default {
  setDaily(state, data) {
    Vue.set(state, 'daily', {
      total: data.total,
      stations: Object.keys(data.stations)
        .map((key) => data.stations[key]),
    });
  },
  setMonthly(state, data) {
    Vue.set(state, 'monthly', {
      total: data.total,
      stations: Object.keys(data.stations)
        .map((key) => data.stations[key]),
    });
  },
  setServiceLevel(state, data) {
    Vue.set(state, 'serviceLevel', data);
  },
};
