import Vue from 'vue';

export default {
  setInbound(state, data) {
    Vue.set(state.callCenter, 'inbound', data);
  },
  setOutbound(state, data) {
    Vue.set(state.callCenter, 'outbound', data);
  },
  setLivechat(state, data) {
    Vue.set(state, 'liveChat', data);
  },
  setGI(state, data) {
    Vue.set(state, 'GI', data);
  },
  setOsec(state, data) {
    Vue.set(state, 'osec', data);
  },
  setStations(state, stations) {
    Object.keys(stations).forEach((station) => {
      const data = stations[station];
      switch (station) {
        case 'call-center':
          Vue.set(state.callCenter, 'inbound', data.inbound);
          Vue.set(state.callCenter, 'outbound', data.outbound);
          break;
        case 'live-chat':
          Vue.set(state, 'liveChat', data);
          break;
        case 'gi':
          Vue.set(state, 'GI', data);
          break;
        case 'osec':
          Vue.set(state, 'osec', data);
          break;
        default:
          break;
      }
    });
  },
};
