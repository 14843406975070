<template>
  <b-modal class="help-modal" :active.sync="help" @close="closeHelp">
    <header class="modal-card-head">
      <p class="modal-card-title">HOT KEY</p>
    </header>
    <section class="modal-card-body">
      <b-table :data="hotkey.data" :columns="hotkey.columns" />
    </section>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      hotkey: {
        data: [
          {
            key: 'F1',
            action: 'Show/Hide HOTKEY',
          },
          {
            key: 'Space',
            action: 'Play/Pause slide',
          },
          {
            key: 'Arrow left',
            action: 'Go to left',
          },
          {
            key: 'Arrow right',
            action: 'Go to right',
          },
          {
            key: 'R',
            action: 'Refresh data',
          },
          {
            key: 'F',
            action: 'Enter to fullscreen',
          },
        ],
        columns: [
          {
            field: 'key',
            label: 'Key',
          },
          {
            field: 'action',
            label: 'Action',
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters([
      'help',
    ]),
  },
  methods: {
    ...mapActions(['closeHelp']),
  },
};
</script>

<style lang="sass">
.help-modal
  .modal-card-head
    background-color: #03052f
    .modal-card-title
      color: $is-white
      font-size: 3rem
  .modal-card-body
    background-color: rgba($is-blue, 0.5)
</style>
