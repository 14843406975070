import Vue from 'vue';
import Buefy from 'buefy';
import Axios from 'axios';
import VueAxios from 'vue-axios';
import Moment from 'moment';
import VueMoment from 'vue-moment';
import VueNumeralFilterInstaller from 'vue-numeral-filter';
import VueProgressBar from 'vue-progressbar';
import VueSocketIO from 'vue-socket.io';
import { Slide } from 'vue-carousel';
import App from './App.vue';
import router from './router';
import store from './store';
import Carousel from './components/VueCarousel/Carousel';
import './registerServiceWorker';

Vue.use(Buefy, {
  defaultIconPack: 'fa',
});

Axios.defaults.baseURL = process.env.VUE_APP_API_URL;
Vue.use(VueAxios, Axios);

Vue.use(Moment);
Vue.use(VueMoment);

Vue.use(VueNumeralFilterInstaller);

Vue.use(VueProgressBar, {
  color: '#fff',
  thickness: '5px',
  transition: {
    speed: '0.2s',
  },
  location: 'bottom',
  autoFinish: true,
});

Vue.use(new VueSocketIO({
  connection: process.env.VUE_APP_SOCKET_URL,
}));

Vue.component('carousel', Carousel);
Vue.component('slide', Slide);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
