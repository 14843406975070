<template>
  <div>
    <p class="is-size-4 is-uppercase has-text-centered mt-2 mb-1">
      Office of Information Technology & Service Development (ITSD)
    </p>
    <div class="columns is-vcentered">
      <div
        v-if="director"
        class="column is-one-third has-text-centered"
      >
        <agent-component
          class="has-border is-larger"
          :image="director.image"
          :prefix="director.prefix"
          :firstname="director.firstname"
          :lastname="director.lastname"
          :position="director.position"
          :no-border="true"
        />
      </div>
      <div
        class="column has-text-centered"
      >
        <div class="columns is-centered">
          <div
            class="column"
            v-for="item in seniorProfessionals.slice(0,3)"
            :key="item.id"
          >
          <agent-component
            class="has-border is-small"
            :image="item.image"
            :prefix="item.prefix"
            :firstname="item.firstname"
            :position="item.position"
            :no-border="true"
          />
          </div>
        </div>
        <div class="columns is-centered">
          <div
            class="column"
            v-for="item in [].concat(professionals, practitioners).slice(0,3)"
            :key="item.id"
          >
            <agent-component
              class="has-border is-small"
              :image="item.image"
              :prefix="item.prefix"
              :firstname="item.firstname"
              :position="item.position"
              :no-border="true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AgentComponent from '@/components/Agent.vue';

export default {
  components: {
    AgentComponent,
  },
  computed: {
    ...mapGetters('board', ['director', 'seniorProfessionals', 'professionals', 'practitioners']),
  },
  methods: {
    ...mapActions('board', [
      'getLeaderboard',
    ]),
  },
  mounted() {
    this.getLeaderboard();
  },
};
</script>
