const status = ['ready', 'talk', 'work', 'break', 'offline'];
const activeStatus = ['ready', 'talk', 'work', 'break'];

const sortByStatus = (a, b) => {
  const aStatusIndex = status.findIndex((i) => i === a.status);
  const bStatusIndex = status.findIndex((i) => i === b.status);

  if (aStatusIndex !== bStatusIndex) {
    return aStatusIndex - bStatusIndex;
  }

  return a.firstname.localeCompare(b.firstname);
};

export default {
  agents: (state) => state.agents,
  agentCallCenters: (state) => (
    state.agents.filter((i) => ([
      'call-center',
      'live-chat',
    ].includes(i.station)))
      .sort(sortByStatus)
  ),
  agentGIs: (state) => (
    state.agents.filter((i) => ([
      'gi-bangkrasor',
      'gi-ratchada',
    ].includes(i.station)))
      .sort(sortByStatus)
  ),
  agentOSECs: (state) => (
    state.agents.filter((i) => ([
      'osec',
    ].includes(i.station)))
      .sort(sortByStatus)
  ),
  unitStatus: (state) => ([
    {
      status: 'Ready',
      value: state.agents.filter((i) => i.status === 'ready').length,
    },
    {
      status: 'Talk',
      value: state.agents.filter((i) => i.status === 'talk').length,
    },
    {
      status: 'Work',
      value: state.agents.filter((i) => i.status === 'work').length,
    },
    {
      status: 'Break',
      value: state.agents.filter((i) => i.status === 'break').length,
    },
    {
      status: 'Total',
      value: state.agents.filter((i) => activeStatus.includes(i.status)).length,
    },
  ]),
  groupAgent: (state) => (
    state.agents.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / 9);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // eslint-disable-line no-param-reassign
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, [])
  ),
};
