<template>
  <div>
    <p class="heading">agents</p>
    <div class="container is-fluid">
      <folder-component
        v-for="item in value"
        :key="item.id"
      >
        <template v-slot:left>
          <div class="column is-one-third has-text-centered">
            <agent-component
              :image="item.image"
              :status="item.status"
            >
              <template v-slot:name>
                {{ item.firstname }}<br>
                ({{ item.prefix }})
              </template>
            </agent-component>
          </div>
          <div class="column">
            <b-table
              class="station"
              :data="performanceToArray(item.performance)"
              :columns="columns"
            />
          </div>
        </template>
      </folder-component>
    </div>
  </div>
</template>

<script>
import FolderComponent from '@/components/element/Folder.vue';
import AgentComponent from '@/components/Agent.vue';

export default {
  props: {
    value: Array,
  },
  components: {
    FolderComponent,
    AgentComponent,
  },
  methods: {
    performanceToArray(performance) {
      const performanceArr = [];

      Object.keys(performance).forEach((item) => {
        performanceArr.push(performance[item]);
      });

      return performanceArr;
    },
  },
  data() {
    return {
      columns: [
        {
          field: 'title',
          label: '',
        },
        {
          field: 'daily',
          label: 'D',
          numeric: true,
        },
        {
          field: 'monthly',
          label: 'M',
          numeric: true,
        },
      ],
    };
  },
};
</script>

<style lang="sass" scoped>
.container
  position: relative
  top: -20px
  height: 795px
  margin-left: 0
  margin-right: 0
  overflow: hidden
  display: flex
  flex-flow: column wrap
  align-content: flex-start
  .folder
    background: url('~@/assets/agents/folder.png') no-repeat center top -10px
    width: 626px
    height: 256px
    padding: 22px 46px 20px
    .columns
      .column
        &:first-child
          padding-right: 0
          border-right: none
        &:last-child
          padding-left: 0
        .agent
          margin-top: 2.5rem
          .avatar
            width: 120px
            height: 120px
</style>

<style lang="sass">
.b-table.station
  thead,
  tbody
    td:nth-child(2)
      width: 15%
    td:nth-child(3)
      width: 20%
</style>
