import Axios from 'axios';

export default {
  async getStations({ commit }) {
    try {
      const { data } = await Axios.get('/stations');
      commit('setStations', data);
    } catch (e) {
      // console.error(e.message);
    }
  },
  async getInbound({ commit }) {
    try {
      const { data } = await Axios.get('/station/call-center/inbound');
      commit('setInbound', data);
    } catch (e) {
      // console.error(e.message);
    }
  },
  async getOutbound({ commit }) {
    try {
      const { data } = await Axios.get('/station/call-center/outbound');
      commit('setOutbound', data);
    } catch (e) {
      // console.error(e.message);
    }
  },
  async getLivechat({ commit }) {
    try {
      const { data } = await Axios.get('/station/live-chat');
      commit('setLivechat', data);
    } catch (e) {
      // console.error(e.message);
    }
  },
  async getGI({ commit }) {
    try {
      const { data } = await Axios.get('/station/gi');
      commit('setGI', data);
    } catch (e) {
      // console.error(e.message);
    }
  },
  async getOsec({ commit }) {
    try {
      const { data } = await Axios.get('/station/osec');
      commit('setOsec', data);
    } catch (e) {
      // console.error(e.message);
    }
  },
};
