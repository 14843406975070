import Axios from 'axios';

export default {
  async getOverview({ commit }) {
    try {
      const { data } = await Axios.get('/overview');

      commit('setDaily', data.daily);
      commit('setMonthly', data.monthly);
      commit('setServiceLevel', data.service_level);
    } catch (e) {
      console.error(e.message);
    }
  },
};
