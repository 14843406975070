import { render, staticRenderFns } from "./FolderMonthly.vue?vue&type=template&id=03498a4a&scoped=true&"
var script = {}
import style0 from "./FolderMonthly.vue?vue&type=style&index=0&id=03498a4a&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03498a4a",
  null
  
)

export default component.exports