export default {
  director: null,
  seniorProfessionals: [],
  professionals: [],
  seniorSupervisors: [],
  supervisors: [],
  topPerformance: {
    month: null,
    agents: [],
  },
};
