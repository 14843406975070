export default {
  daily: {
    total: 0,
    stations: [],
  },
  monthly: {
    total: 0,
    stations: [],
  },
  serviceLevel: 0,
};
