<template>
  <div>
    <p class="heading">overview</p>
    <div class="columns">
      <div class="column is-half">
        <folder-component class="type-a">
          <template v-slot:left>
            <div class="column is-one-third is-v-centered percent-level">
              <p class="title">Service Level</p>
              <p class="type">sl</p>
              <p class="percent">
                {{ serviceLevel }}%
              </p>
            </div>
          </template>
          <template v-slot:right>
            <div class="column">
              <p class="is-size-4">Daily</p>
              <point-component
                v-for="(item, index) in daily.stations"
                :key="index"
                :name="item.name"
                :value="item.total"
              />
              <point-component
                class="total"
                :name="total.name"
                :value="dailyTotal"
              />
            </div>
          </template>
        </folder-component>
        <div class="columns is-vcentered">
          <div class="column is-4">
            <span class="is-size-2 px-2">Monthly</span>
          </div>
          <div class="column is-8">
            <folder-monthly-component>
              <point-monthly-component
                v-for="(item, index) in monthly.stations"
                :key="index"
                :name="item.name"
                :value="item.total"
              />
              <point-monthly-component
                class="total"
                :name="total.name"
                :value="monthlyTotal"
              />
            </folder-monthly-component>
          </div>
        </div>
      </div>
      <div class="column is-half">
        <folder-component
          v-for="station in stationAgents"
          :key="station.name"
          class="type-b"
        >
          <template v-slot:left>
            <div class="column">
              <p class="is-size-4">{{station.name}}</p>
              <agent-component
                v-for="(item, index) in station.data.slice(0, 8)"
                :key="index"
                :image="item.image"
                :status="item.status"
              />
            </div>
          </template>
        </folder-component>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FolderComponent from '@/components/element/Folder.vue';
import FolderMonthlyComponent from '@/components/element/FolderMonthly.vue';
import AgentComponent from '@/components/Agent.vue';
import PointComponent from '@/components/Point.vue';
import PointMonthlyComponent from '@/components/PointMonthly.vue';

export default {
  components: {
    FolderComponent,
    FolderMonthlyComponent,
    AgentComponent,
    PointComponent,
    PointMonthlyComponent,
  },
  data() {
    return {
      total: {
        name: 'Total',
      },
    };
  },
  computed: {
    ...mapGetters('agent', [
      'agents',
      'agentCallCenters',
      'agentGIs',
      'agentOSECs',
    ]),
    ...mapGetters('overview', ['daily', 'monthly', 'serviceLevel']),
    stationAgents() {
      return [
        {
          name: 'Call Center & Live Chat',
          data: this.agentCallCenters.filter((i) => i.status && i.status !== 'offline'),
        },
        {
          name: 'GI',
          data: this.agentGIs.filter((i) => i.status && i.status !== 'offline'),
        },
        {
          name: 'OSEC',
          data: this.agentOSECs.filter((i) => i.status && i.status !== 'offline'),
        },
      ];
    },
    dailyTotal() {
      return this.daily.total || 0;
    },
    monthlyTotal() {
      return this.monthly.total || 0;
    },
  },
};
</script>

<style lang="sass" scoped>
.folder
  &.type-a
    background: url('~@/assets/shared/folder-type-A.png') no-repeat
    background-size: 100% 100% !important
    height: 422px
    padding: 26px 46px
    .point
      &:not(:last-child)
        height: 50px
        margin-bottom: .7rem
  &.type-b
    background: url('~@/assets/overview/folder-type-B.png') no-repeat
    height: 261px
    padding: 45px 46px 19px
    &:not(:first-child)
      margin-top: -20px
    .agent
      margin-right: 0
      &:not(:last-child)
        margin-right: 5px
</style>
