<template>
  <div class="point has-text-centered">
    <span class="name font-bold" ref="name">{{ name }}</span>
    <span class="value" ref="value">{{ value }}</span>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      requied: true,
    },
    value: {
      type: [Number, String],
      requied: true,
    },
  },
};
</script>

<style lang="sass" scoped>
.point
  display: flex
  flex-direction: column
  position: absolute
  width: 193px
  height: 146px
  padding: 34px 0
  line-height: .7
  .name
    font-size: .9em
  .value
    font-size: 1.9em

  &.total
    .name
      font-size: 1.1em
    .value
      font-size: 2.1em
</style>
