<template>
  <div class="unit-status">
    <span class="value" ref="value" :class="status.toLowerCase()">{{ value }}</span>
    <span class="status" ref="status">{{ status }}</span>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      requied: true,
    },
    value: {
      type: [Number, String],
      requied: true,
    },
  },
};
</script>
