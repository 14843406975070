import Vue from 'vue';

export default {
  setAgents(state, agents) {
    Vue.set(state, 'agents', agents);
  },
  deleteAgent(state, id) {
    const index = state.agents.findIndex((i) => parseInt(i.id, 10) === parseInt(id, 10));
    Vue.delete(state.agents, index);
  },
  updateAgent(state, data) {
    const index = state.agents.findIndex((i) => parseInt(i.id, 10) === parseInt(data.id, 10));
    const agent = state.agents[index];

    const $data = {};
    Object.assign($data, agent, data);

    Vue.set(state.agents, index, $data);
  },
  updateStatus(state, agentStatus) {
    agentStatus.forEach((item) => {
      const index = state.agents.findIndex((i) => parseInt(i.id, 10) === parseInt(item.id, 10));
      const agent = state.agents[index];

      const $data = {};
      Object.assign($data, agent, {
        status: item.status,
      });

      Vue.set(state.agents, index, $data);
    });
  },
  updateStation(state, agentStation) {
    agentStation.forEach((item) => {
      const index = state.agents.findIndex((i) => parseInt(i.id, 10) === parseInt(item.id, 10));
      const agent = state.agents[index];

      const $data = {};
      Object.assign($data, agent, {
        station: item.station,
      });

      Vue.set(state.agents, index, $data);
    });
  },
};
