<template>
  <div>
    <p class="heading mt-2">
      leader team
    </p>
    <div class="columns is-centered">
      <div
        v-for="item in seniorSupervisors.slice(0,3)"
        :key="item.name"
        class="column is-one-third has-text-centered"
      >
        <agent-component
          class="has-border is-medium"
          :image="item.image"
          :position="item.position"
          :prefix="item.prefix"
          :firstname="item.firstname"
          :lastname="item.lastname"
          :no-border="true"
        />
      </div>
    </div>
    <div class="columns is-centered">
      <div
        v-for="item in [].concat(supervisors, statisticians).slice(0,3)"
        :key="item.name"
        class="column is-one-third has-text-centered"
      >
        <agent-component
          class="has-border is-medium"
          :image="item.image"
          :position="item.position"
          :prefix="item.prefix"
          :firstname="item.firstname"
          :lastname="item.lastname"
          :no-border="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AgentComponent from '@/components/Agent.vue';

export default {
  components: {
    AgentComponent,
  },
  computed: {
    ...mapGetters('board', ['seniorSupervisors', 'supervisors', 'statisticians']),
  },
  methods: {
    ...mapActions('board', [
      'getLeaderTeam',
    ]),
  },
  mounted() {
    this.getLeaderTeam();
  },
};
</script>

<style lang="sass" scoped>
.columns
  + .columns
    margin-top: -3rem
</style>
