export default {
  callCenter: {
    inbound: {
      answered: {
        daily: 0,
        monthly: 0,
      },
      abandoned: {
        daily: 0,
        monthly: 0,
      },
      serviceLevel: 0,
    },
    outbound: {
      followed_up: 0,
      activities: 0,
    },
  },
  liveChat: {
    daily: 0,
    monthly: 0,
    satisfaction: 0,
  },
  GI: {
    stations: [],
    satisfaction: 0,
  },
  osec: {
    monthly: 0,
    satisfaction: 0,
    camera: [],
    departments: [],
  },
};
