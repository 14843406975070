<template>
  <div class="embed-video">
    <video class="video" ref="video" autoplay loop muted />
  </div>
</template>

<script>
import Hls from 'hls.js';

export default {
  props: {
    video: {
      type: String,
      required: true,
    },
  },
  computed: {
    isHLSSupport() {
      return Hls.isSupported();
    },
    isM3U8Support() {
      return this.$refs.video.canPlayType('application/vnd.apple.mpegurl');
    },
  },
  mounted() {
    if (this.isHLSSupport) {
      const hls = new Hls();

      hls.loadSource(this.video);
      hls.attachMedia(this.$refs.video);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        this.$refs.video.play();
      });
    } else if (this.isM3U8Support) {
      this.$refs.video.src = this.video;
      this.$refs.video.addEventListener('loadedmetadata', () => {
        this.$refs.video.play();
      });
    }
  },
};
</script>

<style lang="sass" scoped>
.embed-video
  width: 100%
  position: relative
  overflow: hidden
  padding: 0
  &:before
    content: ""
    display: block
    padding-top: 56.25%
  .video
    position: absolute
    top: 0
    bottom: 0
    left: 0
    width: 100%
    height: 100%
    border: 0
</style>
