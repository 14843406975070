import Vue from 'vue';

export default {
  setLeaderboard(state, data) {
    Vue.set(state, 'director', data.director);
    Vue.set(state, 'seniorProfessionals', data['senior-professional']);
    Vue.set(state, 'professionals', data.professional);
    Vue.set(state, 'practitioners', data.practitioner);
  },
  setLeaderteam(state, data) {
    Vue.set(state, 'seniorSupervisors', data['senior-supervisor']);
    Vue.set(state, 'supervisors', data.supervisor);
    Vue.set(state, 'statisticians', data.statistician);
  },
  setTopPerformance(state, data) {
    Vue.set(state, 'topPerformance', data);
    // console.log(state.topPerformance);
  },
};
