<template>
  <div class="point level">
    <div class="level-left" ref="name">{{ name }}</div>
    <div class="level-right" ref="value">{{ value }}</div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      requied: true,
    },
    value: {
      type: [Number, String],
      requied: true,
    },
  },
};
</script>

<style lang="sass" scoped>
.point
  background: rgb(0,22,56)
  background: linear-gradient(90deg, rgba(0,22,56,1) 0%, rgba(2,61,149,1) 100%)
  border-width: $border-size-point $border-size-point $border-size-point 0px
  border-style: solid
  border-color: $is-white
  position: relative
  height: 60px
  margin-left: 15px
  padding-left: 15px
  font-size: 1.5em
  &.total
    font-size: 1.875em
  &:before,
  &:after
    content: ""
    position: absolute
    display: block
    background-color: #001637
    border-style: solid
    border-color: $is-white
  &:before
    width: 15px
    height: calc(100% - 12px)
    left: -15px
    top: -$border-size-point
    border-width: $border-size-point 0 0 $border-size-point
  &:after
    width: 20px
    height: 20px
    left: -11px
    bottom: 4px
    transform: rotate(-45deg)
    border-width: 0 0 0 $border-size-point
  .level-right
    min-width: 125px
    height: 100%
    border-left: 1px solid $is-white
    background-color: #0380fa
    justify-content: flex-end
    padding-left: .2em
    padding-right: .2em
  &.total
    background: #01aef0
    border-width: $border-size-total $border-size-total $border-size-total 0px
    border-color: #3dd4ff
    height: 65px
    margin-left: 16px
    &:before,
    &:after
      background-color: #01aef0
      border-color: #3dd4ff
    &:before
      width: 17px
      height: calc(100% - 7px)
      left: -17px
      top: -5px
      border-width: $border-size-total 0 0 $border-size-total
    &:after
      width: 23px
      height: 23px
      left: -12px
      bottom: 0px
      border-width: 0 0 0 $border-size-total
    .level-right
      min-width: 175px
      border-left: 5px solid #3dd4ff
      background-color: #1a3083
</style>
