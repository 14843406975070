<template>
  <main class="main">
    <carousel
      :per-page="1"
      :mouse-drag="false"
      :touch-drag="false"
      :pagination-enabled="false"
      :autoplay="autoPlay"
      :autoplay-timeout="speed"
      :autoplay-hover-pause="false"
      :loop="true"
      ref="carousel"
      @page-change="changed"
    >
      <slide v-for="(page, index) in pages" :key="index">
        <component :is="page.component" :value="page.value" />
      </slide>
    </carousel>
    <div
      class="carousel-buttons"
      :class="{ active: autoPlay }"
    >
      <b-button
        icon-right="step-backward"
        @click="prev"
      />
      <b-button
        class="play"
        icon-right="play"
        @click="pauseAndPlay"
      />
      <b-button
        class="pause"
        icon-right="pause"
        @click="pauseAndPlay"
      />
      <b-button
        icon-right="step-forward"
        @click="next"
      />
    </div>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Overview from '@/views/Overview.vue';
import Callcenter from '@/views/Callcenter.vue';
import Gi from '@/views/Gi.vue';
import Osec from '@/views/Osec.vue';
import LeaderBoard from '@/views/LeaderBoard.vue';
import LeaderTeam from '@/views/LeaderTeam.vue';
import TopPerformance from '@/views/TopPerformance.vue';
import Agents from '@/views/Agents.vue';

export default {
  name: 'home',
  components: {
    Overview,
    Callcenter,
    Gi,
    Osec,
    LeaderBoard,
    LeaderTeam,
    TopPerformance,
    Agents,
  },
  data() {
    return {
      autoPlay: false,
      speed: 10000,
      help: false,
    };
  },
  created() {
    window.addEventListener('keydown', this.onkey);
  },
  computed: {
    ...mapGetters('agent', {
      agents: 'groupAgent',
    }),
    ...mapGetters(['isLoading']),
    pages() {
      return [
        {
          component: 'overview',
        },
        {
          component: 'callcenter',
        },
        {
          component: 'gi',
        },
        {
          component: 'osec',
        },
        {
          component: 'leader-board',
        },
        {
          component: 'leader-team',
        },
        {
          component: 'top-performance',
        },
        ...this.agents.map((value) => ({
          component: 'agents',
          value,
        })),
      ];
    },
  },
  methods: {
    ...mapActions('agent', [
      'getAgents',
      'getAgentStatus',
      'getAgentStation',
    ]),
    ...mapActions('overview', [
      'getOverview',
    ]),
    ...mapActions('station', [
      'getStations',
    ]),
    ...mapActions([
      'loading',
      'loaded',
      'toggleHelp',
    ]),
    onkey(event) {
      switch (event.code) {
        case 'Space':
          this.pauseAndPlay();
          break;
        case 'F5':
          this.play();
          break;
        case 'Escape':
          this.pause();
          break;
        case 'ArrowLeft':
        case 'PageUp':
          this.prev();
          break;
        case 'ArrowRight':
        case 'PageDown':
          this.next();
          break;
        case 'KeyR':
          this.fetchData();
          break;
        case 'KeyF':
        case 'Period':
          this.toggleFullScreen();
          break;
        case 'F1':
          this.toggleHelp();
          break;
        default:
          break;
      }
    },
    pauseAndPlay() {
      if (this.isLoading) {
        return false;
      }

      this.autoPlay = !this.autoPlay;

      return true;
    },
    play() {
      this.autoPlay = true;
    },
    pause() {
      this.autoPlay = false;
    },
    next() {
      if (this.isLoading) {
        return false;
      }

      try {
        this.$refs.carousel.handleNavigation('forward');
      } catch (e) {
        // console.error(e.message);
      }

      return true;
    },
    prev() {
      if (this.isLoading) {
        return false;
      }

      try {
        this.$refs.carousel.handleNavigation('backward');
      } catch (e) {
        // console.error(e.message);
      }

      return true;
    },
    toggleFullScreen() {
      // const currentWindow = remote.getCurrentWindow();
      // currentWindow.setFullScreen(!currentWindow.isFullScreen());
    },
    changed() {
      if (this.autoPlay) {
        this.$Progress.start(this.speed / 2.8);
      }
    },
    async fetchData() {
      this.loading();

      await this.getAgents();
      await this.getAgentStatus();
      await this.getAgentStation();
      await this.getOverview();
      await this.getStations();

      this.loaded();
    },
  },
  async mounted() {
    await this.fetchData();
  },
  watch: {
    isLoading() {
      this.autoPlay = !this.isLoading;
    },
    autoPlay() {
      if (this.autoPlay) {
        this.$Progress.start(this.speed / 2.8);
      } else {
        this.$Progress.finish();
      }
    },
  },
};
</script>

<style lang="sass">
.VueCarousel-slide
  position: relative
.carousel-buttons
  height: 52px
  width: 210px
  position: absolute
  left: 16px
  bottom: -63px
  z-index: 1000
  .button
    width: 52px
    height: 52px
    display: inline-block
    border-width: 5px
    border-color: $is-white
    background-color: transparent
    border-radius: 180px
    padding: 0
    font-size: .4em
    margin: 0 .8rem
    &.play
      display: inline-block
    &.pause
      display: none
    &:hover,
    &:focus
      color: inherit
  &.active
    .button
      &.play
        display: none
      &.pause
        display: inline-block
</style>
