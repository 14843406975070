<template>
  <div>
    <p class="heading">
      call center & live chat
    </p>
    <div class="columns">
      <div class="column is-half">
        <folder-component class="type-a">
          <template v-slot:left>
            <div class="column is-one-third is-v-centered percent-level">
              <p class="title">Service Level</p>
              <p class="type">sl</p>
              <p class="percent">
                {{ inbound.service_level }}%
              </p>
            </div>
          </template>
          <template v-slot:right>
            <div class="column">
              <p class="is-size-4">Inbound</p>
              <div class="inbound-point">
                <point-component
                  name="Daily Answered"
                  :value="inbound.answered.daily"
                />
                <point-component
                  name="Monthly Answered"
                  :value="inbound.answered.monthly"
                />
                <point-component
                  name="Monthly Abandoned"
                  :value="inbound.abandoned.monthly"
                />
              </div>
            </div>
          </template>
        </folder-component>
        <div class="columns is-centered">
          <div class="column">
            <folder-unit-status-component>
              <unit-status-hexagon-component
                v-for="item in unitStatus"
                :key="item.status"
                :status="item.status"
                :value="item.value"
              />
            </folder-unit-status-component>
          </div>
        </div>
      </div>

      <div class="column is-half">
        <folder-component class="type-b">
          <template v-slot:left>
            <div class="column">
              <p class="is-size-4">
                Outbound
              </p>
              <point-component
                name="Followed-up Case"
                :value="outbound.followed_up"
              />
              <point-component
                name="Activities to Attend"
                :value="outbound.activities"
              />
            </div>
          </template>
        </folder-component>
        <folder-component class="type-b">
          <template v-slot:left>
            <div class="column">
              <p class="is-size-4">
                Live Chat
              </p>
              <point-component
                name="Daily"
                :value="liveChat.daily"
              />
              <point-component
                name="Monthly"
                :value="liveChat.monthly"
              />
            </div>
          </template>
          <template v-slot:right>
            <div class="column is-one-third is-v-centered percent-level">
              <p class="title">Satisfaction</p>
              <p class="percent">
                {{ liveChat.satisfaction }}%
              </p>
            </div>
          </template>
        </folder-component>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FolderComponent from '@/components/element/Folder.vue';
import FolderUnitStatusComponent from '@/components/FolderUnitStatus.vue';
import PointComponent from '@/components/Point.vue';
import UnitStatusHexagonComponent from '@/components/UnitStatusHexagon.vue';

export default {
  components: {
    FolderComponent,
    FolderUnitStatusComponent,
    PointComponent,
    UnitStatusHexagonComponent,
  },
  computed: {
    ...mapGetters('agent', ['unitStatus']),
    ...mapGetters('station', [
      'inbound',
      'outbound',
      'liveChat',
    ]),
  },
};
</script>

<style lang="sass" scoped>
.folder
  margin-top: -1rem
  &.type-a
    background: url('~@/assets/shared/folder-type-A.png') no-repeat
    height: 399px
    padding: 26px 46px
  &.type-b
    background: url('~@/assets/callcenter/folder-type-B.png') no-repeat
    height: 380px
    padding: 45px 46px 19px

.inbound-point
  font-size: 36px
</style>
