<template>
  <div>
    <p class="heading">
      general information (gi)
    </p>
    <div class="columns is-variable is-0">
      <div class="column is-folder is-half"
        v-for="(item, index) in GI.stations"
        :key="index"
      >
        <folder-component>
          <template v-slot:left>
            <div class="column">
              <p class="is-size-4">
                {{item.name}}
              </p>
              <div class="wrapper-video">
                <carousel
                  v-if="item.camera && item.camera.length"
                  :per-page="1"
                  :mouse-drag="false"
                  :touch-drag="false"
                  :autoplay="true"
                  :autoplay-timeout="speed"
                  :autoplay-hover-pause="false"
                  :loop="true"
                >
                  <slide
                    v-for="(video, index) in item.camera"
                    :key="index"
                  >
                    <video-component
                      :video="video.url"
                    />
                  </slide>
                </carousel>
              </div>
              <div class="wrapper-point">
                <point-component
                  name="Daily"
                  :value="item.daily"
                />
                <point-component
                  name="Monthly"
                  :value="item.monthly"
                />
              </div>
            </div>
          </template>
        </folder-component>
      </div>

      <div class="column is-full">
        <folder-component class="folder-center">
          <template v-slot:left>
            <div class="column is-full has-text-centered percent-level">
              <p class="title">
                Satisfaction
              </p>
              <p class="percent">
                {{GI.satisfaction}}%
              </p>
            </div>
          </template>
        </folder-component>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FolderComponent from '@/components/element/Folder.vue';
import PointComponent from '@/components/Point.vue';
import VideoComponent from '@/components/Video.vue';

export default {
  components: {
    FolderComponent,
    PointComponent,
    VideoComponent,
  },
  data() {
    return {
      speed: 60000,
    };
  },
  computed: {
    ...mapGetters('station', ['GI']),
  },
};
</script>

<style lang="sass" scoped>
.folder
  margin-top: -1.5rem
  .wrapper-video
    margin: 2rem 0
    width: 677px
    height: 380px
  .wrapper-point
    width: 677px
    display: inline-block
    padding: 2rem 9rem
  &.folder-center
    background: url('~@/assets/gi/folder-center.png') no-repeat
    width: 270px
    height: 248px
    position: absolute
    left: 0
    right: 0
    bottom: 22px
    margin: 0 auto
    padding: 4rem 4rem 0
    font-size: 3.5rem
.is-folder
  .folder
    height: 759px
    padding: 30px 50px
  &:nth-child(1)
    .folder
      background: url('~@/assets/gi/folder-left.png') no-repeat
      text-align: left
  &:nth-child(2)
    .folder
      background: url('~@/assets/gi/folder-right.png') no-repeat
      text-align: right

.percent-level
  font-size: 2.8rem
  .title
    font-size: 1.8em
</style>
