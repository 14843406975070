<template>
  <header class="header">
    <nav class="navbar">
      <div class="navbar-start">
        <img src="../assets/header/service-center.png" class="img" alt="">
      </div>
      <div class="navbar-brand">
        <div class="d-block has-text-centered">
          <p class="is-size-2 line-height-min">{{ currentTime }}</p>
          <p class="is-size-5 line-height-min font-bold">{{ currentDate }}</p>
        </div>
      </div>
      <div class="navbar-end">
        <img src="../assets/header/logo.png" class="img" alt="">
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  data() {
    return {
      currentDate: null,
      currentTime: null,
    };
  },
  methods: {
    updateCurrentTime() {
      this.currentDate = this.$moment().format('dddd, MMMM DD, YYYY');
      this.currentTime = this.$moment().format('LTS');
    },
  },
  created() {
    this.updateCurrentTime();
    setInterval(() => this.updateCurrentTime(), 1 * 1000);
  },
};
</script>

<style lang="sass" scoped>
.header
  .navbar
    height: $header-height
    +gradient-blue-header(180deg)
    box-shadow: 0px 4px 10px 0px rgba(0, 115, 255, 0.8)
    >div
      position: relative
      height: $header-height + 10px
      align-items: center
      justify-content: center
      +gradient-blue-header(180deg)
      &:before,
      &:after
        content: ""
        display: block
        position: absolute
        width: 109px
        height: 137px
        bottom: -7px
      &:before
        left: -109px
        background: url('~@/assets/header/tri-angle-left.png') no-repeat
        background-size: 100% 100%
      &:after
        right: -109px
        background: url('~@/assets/header/tri-angle-right.png') no-repeat
        background-size: 100% 100%
  .navbar-start,
  .navbar-end
    width: 320px
  .navbar-start
    .img
      height: 80px
  .navbar-end
    .img
      height: 110px
  .navbar-brand
    width: 580px
</style>
