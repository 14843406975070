import Vue from 'vue';
import Vuex from 'vuex';
import agent from './modules/agent';
import board from './modules/board';
import overview from './modules/overview';
import station from './modules/station';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: false,
  modules: {
    agent,
    board,
    overview,
    station,
  },
  state: {
    isLoading: false,
    help: false,
  },
  actions: {
    loading({ commit }) {
      commit('isLoading', true);
    },
    loaded({ commit }) {
      commit('isLoading', false);
    },
    openHelp({ commit }) {
      commit('help', true);
    },
    closeHelp({ commit }) {
      commit('help', false);
    },
    toggleHelp({ commit, state }) {
      commit('help', !state.help);
    },
  },
  mutations: {
    isLoading(state, isLoading) {
      Object.assign(state, {
        isLoading,
      });
    },
    help(state, help) {
      Object.assign(state, {
        help,
      });
    },
  },
  getters: {
    isLoading: (state) => state.isLoading,
    help: (state) => state.help,
  },
});
