<template>
  <div class="unit-status has-text-centered">
    <span class="status font-bold" ref="status">{{ status }}</span>
    <span class="value" ref="value">{{ value }}</span>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      requied: true,
    },
    value: {
      type: [Number, String],
      requied: true,
    },
  },
};
</script>

<style lang="sass" scoped>
.unit-status
  display: flex
  flex-direction: column
  position: absolute
  width: 203px
  height: 155px
  padding: 32px 0
  line-height: .8
  .status
    font-size: 1.2em
  .value
    font-size: 2.2em
</style>
