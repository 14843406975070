<template>
  <div>
    <p class="heading">
      osec
    </p>
    <div class="columns is-variable is-0">
      <div class="column is-two-fifths">
        <folder-component class="type-a">
          <template v-slot:left>
            <div class="column">
              <div class="wrapper-video">
                <carousel
                  v-if="osec.camera && osec.camera.length"
                  :per-page="1"
                  :mouse-drag="false"
                  :touch-drag="false"
                  :autoplay="true"
                  :autoplay-timeout="5000"
                  :pagination-enabled="false"
                >
                  <slide
                      v-for="(video, index) in osec.camera"
                      :key="index"
                    >
                    <video-component
                      :video="video.url"
                    />
                  </slide>
                </carousel>
              </div>
            </div>
          </template>
        </folder-component>
        <folder-component class="type-b">
          <template v-slot:left>
            <div class="column is-v-centered">
              <p class="is-size-2 has-shadow">Monthly</p>
              <p class="is-size-1 has-shadow">
                {{ osec.monthly|numeral('0,0') }}
              </p>
            </div>
          </template>
          <template v-slot:right>
            <div class="column is-v-centered percent-level">
              <p class="title">Satisfaction</p>
              <p class="percent">
                {{osec.satisfaction}}%
              </p>
            </div>
          </template>
        </folder-component>
      </div>
      <div class="column">
        <div class="columns is-multiline is-gapless multi-folder">
          <div class="column is-half element-folder"
            v-for="(item, index) in osec.departments"
            :key="index"
          >
            <folder-component>
              <template v-slot:left>
                <div class="column">
                  <p class="title">
                    {{item.name}}
                  </p>
                  <point-component
                    name="Daily"
                    :value="item.daily"
                  />
                  <point-component
                    name="Monthly"
                    :value="item.monthly"
                  />
                </div>
              </template>
            </folder-component>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Carousel, Slide } from 'vue-carousel';
import FolderComponent from '@/components/element/Folder.vue';
import PointComponent from '@/components/Point.vue';
import VideoComponent from '@/components/Video.vue';

export default {
  components: {
    FolderComponent,
    PointComponent,
    VideoComponent,
    Carousel,
    Slide,
  },
  computed: {
    ...mapGetters('station', ['osec']),
  },
};
</script>

<style lang="sass" scoped>
.folder
  &.type-a
    background: url('~@/assets/osec/folder-type-A.png') no-repeat
    height: 453px
    padding: 43px 50px
    margin-top: -19px
    .wrapper-video
      width: 652px
      height: 100%
  &.type-b
    background: url('~@/assets/osec/folder-type-B.png') no-repeat
    height: 338px
    padding: 44px 53px 19px
    font-size: 3rem
    margin-top: -21px

.multi-folder
  .element-folder
    &:nth-child(1),
    &:nth-child(3)
      .folder
        background: url('~@/assets/osec/folder-type-C-left.png') no-repeat center center
    &:nth-child(2),
    &:nth-child(4)
      .folder
        background: url('~@/assets/osec/folder-type-C-right.png') no-repeat center center

    .folder
      height: 388px
      padding: 66px 47px
      background-size: 102% auto !important
      margin-top: -14px
      .title
        font-size: $is-size-5
        line-height: .8
        margin-bottom: 2rem
        height: 80px

.percent-level
  font-size: 4.4rem
</style>
