<template>
  <div>
    <p class="heading mt-2">
      top performance
    </p>
    <p class="is-size-4 is-uppercase has-text-centered">
      {{topPerformance.month}}
    </p>
    <template v-if="topPerformance.agents.length >= 3">
      <div class="columns">
        <div
          class="column has-text-centered"
          v-for="(item, index) in topPerformance.agents.slice(0,1)"
          :key="index"
        >
          <agent-component
            class="has-border is-large"
            :image="item.image"
            :name="item.name"
            :badge="index + 1"
            :no-border="true"
          />
        </div>
      </div>
      <div class="columns push-top">
        <div
          class="column has-text-centered"
          v-for="(item, index) in topPerformance.agents.slice(1, 3)"
          :key="index"
        >
          <agent-component
            class="has-border is-large"
            :image="item.image"
            :name="item.name"
            :badge="index + 2"
            :no-border="true"
          />
        </div>
      </div>
    </template>
    <template v-else>
      <div class="columns">
        <div
          class="column has-text-centered"
          v-for="(item, index) in topPerformance.agents"
          :key="index"
        >
          <agent-component
            class="has-border is-large"
            :image="item.image"
            :name="item.name"
            :badge="topPerformance.agents.length > 1 ? index + 1 : null"
            :no-border="true"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AgentComponent from '@/components/Agent.vue';

export default {
  components: {
    AgentComponent,
  },
  computed: {
    ...mapGetters('board', ['topPerformance']),
  },
  methods: {
    ...mapActions('board', [
      'getTopPerformance',
    ]),
  },
  mounted() {
    this.getTopPerformance();
  },
};
</script>

<style lang="sass" scoped>
.push-top
  margin-top: -22rem
</style>
