import { Carousel } from 'vue-carousel';

export default {
  mixins: [Carousel],
  methods: {
    getCarouselWidth() {
      this.carouselWidth = this.$refs['VueCarousel-inner'].clientWidth;
      return this.carouselWidth;
    },
  },
};
