<template>
  <footer class="footer is-paddingless">
    <nav class="navbar">
      <div class="navbar-brand">
        &nbsp;
      </div>
      <div class="navbar-start">
        <div class="navbar-item">Unit Status</div>
        <div class="navbar-item">
          <unit-status
            v-for="item in unitStatus.slice(0,4)"
            :key="item.status"
            :status="item.status"
            :value="item.value"
          />
        </div>
      </div>
      <div class="navbar-end">
        <span class="font-italic">
          COOL CALL SYSTEM
        </span>
        <img src="../assets/footer/mascot.png" class="img" alt="">
      </div>
    </nav>
    <div class="progress-bar">
      <vue-progress-bar />
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';
import UnitStatus from '@/components/UnitStatus.vue';

export default {
  components: {
    UnitStatus,
  },
  data() {
    return {
      ready: null,
      talk: null,
      work: null,
      break: null,
    };
  },
  computed: {
    ...mapGetters('agent', ['unitStatus']),
  },
};
</script>

<style lang="sass">
.footer
  position: absolute
  left: 0
  bottom: 0
  width: 100%
  .navbar
    height: $footer-height
    +gradient-blue-footer(180deg)
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.5)
    .navbar-brand,
    .navbar-end
      +gradient-blue-footer(180deg)
      height: 105px
      position: relative
      top: -32px
      z-index: 10000
      &:before
        content: ""
        display: block
        position: absolute
        top: 0
        width: 85px
        height: 100%
    .navbar-brand
      width: 210px
      &:before
        right: -84px
        background: url('~@/assets/footer/tri-angle-right.png') no-repeat
        background-size: 100% 100%
    .navbar-end
      width: 800px
      justify-content: flex-start
      align-items: center
      font-size: 2.1em
      padding-top: 10px
      &:before
        left: -84px
        background: url('~@/assets/footer/tri-angle-left.png') no-repeat
        background-size: 100% 100%
      .img
        position: absolute
        right: 20px
        bottom: 0
        width: auto
        height: 118%
    .navbar-start
      padding: 0 8rem
      .navbar-item
        padding: 1.5rem 0.75rem
        font-family: $font-bold
      .unit-status
        margin-right: 1.4rem
        line-height: 1
        span
          display: inline-flex
        .value
          width: 45px
          height: 45px
          line-height: 45px
          justify-content: center
          border-radius: 180px
          &.ready
            background-color: $is-ready
          &.talk
            background-color: $is-talk
          &.work
            background-color: $is-work
          &.break
            background-color: $is-break
        .status
          margin: 0 1.4rem
        &:not(:last-child)
          border-right: 1px solid $is-white
.progress-bar
  bottom: 74px !important
  left: 230px !important
  position: absolute !important
  z-index: 10
  width: 900px
  .__cov-progress
    position: static !important
</style>
