<template>
  <div class="agent has-text-centered">
    <figure class="avatar" ref="avatar" :class="{[status]: true, 'no-border': noBorder}">
      <img :src="image" ref="image">
      <div v-if="badge > 0" ref="badge" class="badge">
        <span>{{ badge }}</span>
        <sup>{{ numberOrdinal }}</sup>
      </div>
    </figure>
    <p class="name" ref="name">
      <slot name="name">
        {{ fullname }}
      </slot>
    </p>
    <p class="position font-light" ref="position">{{ position }}</p>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      requied: false,
      validator(value) {
        return /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-/]))?/.test(value);
      },
    },
    prefix: {
      type: String,
      requied: false,
    },
    firstname: {
      type: String,
      requied: false,
    },
    lastname: {
      type: String,
      requied: false,
    },
    name: {
      type: String,
      requied: false,
    },
    position: {
      type: String,
      requied: false,
    },
    status: {
      type: String,
      requied: false,
    },
    badge: {
      type: [Number, String],
      requied: false,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fullname() {
      if (this.name) {
        return this.name.trim();
      }

      const name = [
        (this.prefix || '').trim(),
        (this.firstname || '').trim(),
        (this.lastname || '').trim(),
      ].filter((i) => i.length);

      return name.join(' ');
    },
    numberOrdinal() {
      const ordinals = ['th', 'st', 'nd', 'rd'];
      const value = this.badge % 100;

      return (ordinals[(value - 20) % 10] || ordinals[value] || ordinals[0]);
    },
  },
};
</script>

<style lang="sass" scoped>
.agent
  display: inline-block
  .avatar
    border-radius: 180px
    display: inline-block
    width: 100px
    height: 100px
    position: relative
    background-color: $is-gray
    &:not(.no-border)
      border: 8px solid $is-gray
    &.ready
      border-color: $is-ready
    &.talk
      border-color: $is-talk
    &.work
      border-color: $is-work
    &.break
      border-color: $is-break
    img
      border-radius: 50%
      width: 100%
      height: 100%
      object-fit: cover
      object-position: center
    .badge
      position: absolute
      left: 0
      right: 0
      bottom: -7px
      margin: 0 auto
      +circle(80px, 80px)
      background: $is-black
      border: 6px solid $is-light-blue
      font-size: 6rem
      padding: 7px
      text-align: right
      sup
        font-size: .5em
  .name
    padding-top: 1rem
    font-size: .8em
  .position
    font-size: .6em
  &.is-small
    .avatar
      width: 250px
      height: 250px
      padding: 25px
    .name
      font-size: 1em
    .position
      font-size: .8em
  &.is-medium
    .avatar
      width: 302px
      height: 302px
      padding: 26px
    .name
      font-size: 1.1em
      margin-top: -2rem
    .position
      font-size: .9em
  &.is-large
    .avatar
      width: 360px
      height: 360px
      padding: 30px
    .name
      font-size: 1.2em
    .position
      font-size: 1em
  &.is-larger
    .avatar
      width: 500px
      height: 500px
      padding: 48px
    .name
      font-size: 1.6em
    .position
      font-size: 1.2em
  &.has-border
    .avatar
      background: url('~@/assets/shared/border-avatar.png') no-repeat
      background-size: 100% 100%
      border-radius: 0
      img
        width: 100%
        height: 100%
        object-fit: cover
</style>
