import Axios from 'axios';

export default {
  async getLeaderboard({ commit }) {
    try {
      const { data } = await Axios.get('/leader-board');
      commit('setLeaderboard', data);
    } catch (e) {
      console.error(e.message);
    }
  },
  async getLeaderTeam({ commit }) {
    try {
      const { data } = await Axios.get('/leader-team');
      commit('setLeaderteam', data);
    } catch (e) {
      console.error(e.message);
    }
  },
  async getTopPerformance({ commit }) {
    try {
      const { data } = await Axios.get('/top-performance');
      commit('setTopPerformance', data);
    } catch (e) {
      console.error(e.message);
    }
  },
};
