<template>
  <div id="app">
    <header-component/>
    <router-view/>
    <footer-component/>
    <help-modal />
    <b-loading
      :is-full-page="true"
      :active.sync="isLoading"
      :can-cancel="true"
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import HeaderComponent from './components/Header.vue';
import FooterComponent from './components/Footer.vue';
import HelpModal from './components/Modals/Help.vue';

export default {
  components: {
    HeaderComponent,
    FooterComponent,
    HelpModal,
  },
  data() {
    return {
      socketConnected: false,
    };
  },
  sockets: {
    connect() {
      // console.log('socket connected');
      this.socketConnected = true;
    },
  },
  computed: {
    isLoading: {
      get() {
        return this.$store.getters.isLoading;
      },
      set(isLoading) {
        return this.$store.commit('isLoading', isLoading);
      },
    },
  },
  methods: {
    ...mapMutations('agent', [
      'updateAgent',
      'updateStatus',
      'updateStation',
      'deleteAgent',
    ]),
    ...mapMutations('overview', [
      'setDaily',
      'setMonthly',
      'setServiceLevel',
    ]),
    ...mapMutations('station', ['setStations']),
    ...mapMutations('board', ['setTopPerformance']),
    socketInit() {
      this.sockets.subscribe('broadcast', ({ event, data }) => {
        switch (event) {
          case 'agent.status':
            this.handleAgentStatus(data);
            break;
          case 'agent.delete':
            this.handleAgentDelete(data);
            break;
          case 'agent-award':
            this.handleAgentAward(data);
            break;
          case 'cks.case':
          case 'cks.inbound':
          case 'cks.outbound':
            this.handleCaseCreated(data);
            break;
          case 'convergence.summary':
            this.handleConvergenceSummary(data);
            break;
          default:
            break;
        }
      });
    },
    handleAgentStatus(agent) {
      this.updateStatus([
        agent,
      ]);
      this.updateStation([
        agent,
      ]);
    },
    handleAgentDelete({ id }) {
      this.deleteAgent(id);
    },
    handleAgentAward(data) {
      this.setTopPerformance(data);
    },
    handleCaseCreated({ agent, overview, stations }) {
      this.updateAgent(agent);
      this.setDaily(overview.daily);
      this.setMonthly(overview.monthly);
      this.setServiceLevel(overview.service_level);
      this.setStations(stations);
    },
    handleConvergenceSummary({ overview, stations }) {
      this.setDaily(overview.daily);
      this.setMonthly(overview.monthly);
      this.setStations(stations);
    },
  },
  mounted() {
    this.socketInit();
  },
};
</script>
<style lang="sass">
@import ~@/sass/main.sass
#app
  height: 100%
  background: url('~@/assets/shared/main-bg.jpg') no-repeat center center/cover
  position: relative
  overflow: hidden
</style>
